/**
 * Increament Decreament Button for Catalog Items
 */
import React from 'react';

const IncDecButton = ({
  quantity,
  onChangeEventhandle,
  showAddAction = -1,
  catalogCta,
  showLoader,
  disableAddition = false,
}) => {
  const styleClass = catalogCta ? 'addtocart' : 'pdp-addtocart';

  return (
    <div
      className={`${styleClass}-btn`}
      style={
        !catalogCta
          ? { backgroundColor: `${quantity <= 0 ? '#EA5151' : '#ffffff'}` }
          : { backgroundColor: '#ffffff' }
      }
    >
      {showAddAction < 0 ? (
        <div
          className={`${styleClass}-btn__changeIcon`}
          onClick={() => onChangeEventhandle('add')}
        >
          <span>ADD{!catalogCta && '+'}</span>
          {catalogCta && (
            <img
              loading="lazy"
              src="/assets/images/cta-icon-add@2x.png"
              alt="Add Item button"
            />
          )}
        </div>
      ) : (
        <article style={{ display: 'flex' }}>
          <div
            className={`${styleClass}-btn__changeIcon`}
            onClick={() => onChangeEventhandle('dec')}
          >
            <img
              loading="lazy"
              src="/assets/images/cta-icon-remove@2x.png"
              alt="Decreament button"
            />
          </div>
          <div className={`${styleClass}-btn__text`}>{quantity}</div>
          <div
            className={`${styleClass}-btn__changeIcon`}
            onClick={() => onChangeEventhandle('inc')}
          >
            <div className={`din ${disableAddition ? 'o-20' : 'o-100'}`}>
              <img
                loading="lazy"
                src="/assets/images/cta-icon-add@2x.png"
                alt="Increment button"
              />
            </div>
          </div>
        </article>
      )}
      {showLoader && <div className="loading-wrapper"></div>}
    </div>
  );
};

export default IncDecButton;
